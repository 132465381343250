import {
  ChangeDetectionStrategy,
  Component,
  computed,
  contentChildren,
  forwardRef,
  input,
  linkedSignal,
  output,
  signal,
} from '@angular/core';
import {
  ControlValueAccessor,
  NG_VALUE_ACCESSOR,
  ReactiveFormsModule,
} from '@angular/forms';
import { equals } from '@softline/core';
import { SelectOptionBase } from './select-option/select-option-base';
import { SelectOptionHeaderDirective } from './select-option/select-option-header.directive';
import { SelectOptionSeparatorDirective } from './select-option/select-option-separator.directive';
import { SelectOptionDirective } from './select-option/select-option.directive';
import { CommonModule } from '@angular/common';
import { UiCorePipesModule } from '../../../pipes/ui-core-pipes.module';
import { UiCoreDirectivesModule } from '../../../directives/ui-core-directives.module';
import { I18nModule } from '../../../i18n/i18n.module';
import { L10nModule } from '../../../l10n/l10n.module';

@Component({
  selector: 'soft-select',
  templateUrl: './select.component.html',
  styleUrls: ['./select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => SelectComponent),
      multi: true,
    },
  ],
  imports: [
    CommonModule,
    ReactiveFormsModule,
    UiCorePipesModule,
    UiCoreDirectivesModule,
    I18nModule,
    L10nModule,
  ],
  changeDetection: ChangeDetectionStrategy.OnPush,
  host: {
    'class.readonly': 'readonly()',
  },
})
export class SelectComponent<T = any> implements ControlValueAccessor {
  private onChange: Function = () => {};
  private onTouch: Function = () => {};

  isOpen = signal(false);

  readonly = input(false);
  placeholder = input<string | null>(null);
  valueInput = input<T | null>(null, { alias: 'value' });

  options = contentChildren(SelectOptionBase);

  value = linkedSignal<T | null>(() => this.valueInput());
  valueChange = output<T>();

  selectedOption = computed(() => {
    return this.options().find(
      (option) => this.isOption(option) && equals(option.value(), this.value())
    ) as SelectOptionDirective<T> | null;
  });

  registerOnChange(fn: any): void {
    this.onChange = fn;
  }

  registerOnTouched(fn: any): void {
    this.onTouch = fn;
  }

  writeValue(obj: T): void {
    this.value.set(obj);
  }

  onClick(): void {
    if (this.readonly()) {
      this.isOpen.set(false);
      return;
    }
    this.isOpen.set(!this.isOpen());
  }

  onSelect(value?: T): void {
    if (!value) return;
    this.value.set(value);
    this.isOpen.set(false);
    this.onChange(value);
    this.onTouch();
    this.valueChange.emit(value);
  }

  onOutsideClick(): void {
    if (this.isOpen()) {
      this.isOpen.set(false);
    }
    this.onTouch();
  }

  isOption(value: SelectOptionBase): value is SelectOptionDirective<T> {
    return value instanceof SelectOptionDirective;
  }

  isSeparator(value: SelectOptionBase): value is SelectOptionSeparatorDirective {
    return value instanceof SelectOptionSeparatorDirective;
  }

  isHeader(value: SelectOptionBase): value is SelectOptionHeaderDirective {
    return value instanceof SelectOptionHeaderDirective;
  }
}
