import { Injectable } from '@angular/core';
import { Sort } from '../types/sort';
import moment from 'moment';
import { isIsoDate } from '../../functions/is-iso-date.function';
import { getValue } from '../../functions/get-value.function';

@Injectable()
export class SortService {
  constructor() {}

  sort<T>(values: T[], config: Sort | null | undefined): T[] {
    if (!config) return values;

    return values.sort((f, s) => {
      const fValue = getValue(f, config.property) as never;
      const sValue = getValue(s, config.property) as never;
      let returnValue = (config.comparator ?? this.defaultSortFn)(fValue, sValue);

      if (config.direction === 'desc') returnValue *= -1;
      return returnValue;
    });
  }

  private defaultSortFn(f: any, s: any): number {
    if (isIsoDate(f) && isIsoDate(s)) {
      const fDate = moment(f, false);
      const sDate = moment(f, false);
      if (fDate.isValid() && sDate.isValid())
        return fDate.isAfter(s) ? 1 : fDate.isBefore(s) ? -1 : 0;
    }
    return f > s ? 1 : f < s ? -1 : 0;
  }
}
