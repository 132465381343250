import { Routes } from '@angular/router';

export const routes: Routes = [
  {
    path: 'abau-sale-statistics',
    children: [
      {
        path: 'query',
        loadComponent: () => import('./pages/query/query.component'),
      },
      {
        path: '',
        loadComponent: () => import('./pages/result/result.component'),
      },
    ],
  },
];
