import { ChangeDetectionStrategy, Component, forwardRef, input, output, viewChild } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FieldOkComponent } from '@softline/dynamic';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';
import { DefaultFieldOkConfiguration } from '../field-ok-configuration';
import { FieldOkWrapper } from '../field-ok-wrapper';

@Component({
    selector: 'soft-verkaufsartwerkstatt-field-ok',
    imports: [CommonModule, FieldOkComponent],
    templateUrl: './verkaufsartwerkstatt-field-ok.component.html',
    styleUrl: './verkaufsartwerkstatt-field-ok.component.scss',
    changeDetection: ChangeDetectionStrategy.OnPush,
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => VerkaufsartwerkstattFieldOkComponent),
            multi: true,
        },
    ]
})
export class VerkaufsartwerkstattFieldOkComponent extends FieldOkWrapper<DefaultFieldOkConfiguration> implements ControlValueAccessor {
  readonly fieldOkComponent = viewChild.required(FieldOkComponent);
  override value = input<object | null>(null);
  override configuration = input<DefaultFieldOkConfiguration | null>(null);
  override parameters = input<object>({});
  override readonly = input<boolean>(false);
}
