import { provideSoftAppsModule, withSoftAppsModuleRoutes } from '@softapps/core';
import { routes } from './sale-statistics.routes';

export function provideAbauSaleStatistics() {
  return [
    provideSoftAppsModule(
      {
        name: 'abau-sale-statistics',
        route: 'abau-sale-statistics/query',
        icon: 'fa-regular fa-chart-mixed-up-circle-dollar',
        title: 'Umsatzstatistik',
      },
      withSoftAppsModuleRoutes({ app: routes })
    ),
  ];
}
