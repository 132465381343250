import {
  FieldOkConfig,
  FieldOkListViewComponent,
  FieldOkMultiselectListViewComponent,
  QueryFieldOkListViewComponent,
} from '@softline/dynamic';
import { FaelldtListView } from './list-view/faelldt.list-view';

const defaultSort = { property: 'bisdatum', direction: 'asc' };

export const faelldtFieldOk: FieldOkConfig = {
  name: 'faelldt',
  type: 'default',
  priority: 0,
  defaultView: 'list',
  defaultDataView: 'list',
  template: '{{dekade}} - {{bisdatum:DATE.DEFAULT}}',
  autoQuery: true,
  views: [
    {
      name: 'list',
      component: FieldOkListViewComponent,
      title: 'Auswahl',
      config: {
        component: FaelldtListView,
        defaultSort,
      },
    },
  ],
};
export const faelldtMultiselectFieldOk: FieldOkConfig = {
  name: 'faelldt',
  type: 'multi',
  priority: 0,
  defaultView: 'list',
  defaultDataView: 'list',
  template: '{{dekade}} - {{bisdatum:DATE.DEFAULT}}',
  autoQuery: true,
  views: [
    {
      name: 'list',
      component: FieldOkMultiselectListViewComponent,
      title: 'Auswahl',
      config: {
        component: FaelldtListView,
        defaultSort,
      },
    },
  ],
};

export const faelldtQueryFieldOk: FieldOkConfig = {
  name: 'faelldt',
  type: 'query',
  priority: 0,
  defaultView: 'list',
  defaultDataView: 'list',
  template: '{{dekade}} - {{bisdatum:DATE.DEFAULT}}',
  autoQuery: true,
  views: [
    {
      name: 'list',
      component: QueryFieldOkListViewComponent,
      title: 'Auswahl',
      config: {
        component: FaelldtListView,
        defaultSort,
      },
    },
  ],
};
