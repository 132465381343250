import { Directive, forwardRef, input } from '@angular/core';
import { SelectOptionBase } from './select-option-base';

@Directive({
  selector: 'soft-select-option-separator',
  standalone: true,
  providers: [
    {
      provide: SelectOptionBase,
      useExisting: forwardRef(() => SelectOptionSeparatorDirective),
    },
  ],
})
export class SelectOptionSeparatorDirective extends SelectOptionBase {
  id = input<string | null>(null);

  constructor() {
    super();
  }
}
