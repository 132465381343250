import { Directive, ElementRef, forwardRef, input } from '@angular/core';
import { SelectOptionBase } from './select-option-base';

@Directive({
  selector: 'soft-select-option',
  standalone: true,
  providers: [
    {
      provide: SelectOptionBase,
      useExisting: forwardRef(() => SelectOptionDirective),
    },
  ],
})
export class SelectOptionDirective<T> extends SelectOptionBase {
  id = input<string | null>(null);
  value = input<T>();
  disabled = input(false);

  constructor(public element: ElementRef) {
    super();
  }
}
