import { inject, Injector, Signal, TemplateRef } from '@angular/core';
import { Dismissed, ResolvableModalConfig } from '../types/modal-config';
import { ModalService } from '../modal.service';
import { DatePickerModalComponent } from '../components/date-picker-modal/date-picker-modal.component';

export interface DateModalConfig2 {
  title?: string | Signal<string | null>;
  subtitle?: string | Signal<string | null>;
  content?: TemplateRef<any> | Signal<TemplateRef<any> | null>;
  contentContext?: object | Signal<object | null>;
  text?: string | Signal<string>;
  showCancel?: boolean;
  class?: string;
  value?: string | null;
  dismiss?: boolean;
}

async function selectDate(
  modalService: ModalService,
  config: DateModalConfig2,
  injector: Injector
): Promise<string | null | Dismissed> {
  const modalConfig: ResolvableModalConfig<DatePickerModalComponent, string | null> = {
    component: DatePickerModalComponent,
    dismiss: true,
    title: config.title ?? 'Datum asuwählen',
    subtitle: config.subtitle,
    text: config?.text,
    priority: Number.POSITIVE_INFINITY,
    data: {
      title: config?.title ?? undefined,
      subtitle: config?.subtitle ?? undefined,
      value: config?.value ?? null,
    },
    class: config.class,
    injector: injector,
  };

  return await modalService.open(modalConfig);
}

export function selectDateFn(): (
  config: DateModalConfig2
) => Promise<string | null | Dismissed> {
  const modalService = inject(ModalService);
  const injector = inject(Injector);
  return (config) => selectDate(modalService, config, injector);
}
