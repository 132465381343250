import { computed, Signal, signal } from '@angular/core';
import { LoadCollectionParameters, LoadListParameters, ReadonlyListRepository } from '../../repositories/abstraction';
import { cancelableRxResource } from '../../signals/cancelable-resource';
import { of } from 'rxjs';

export interface RepositoryListServiceOptions<T> {
  defaultItems?: T[];
  skipInitialLoad?: boolean;
}

export class ReadonlyRepositoryListService<T> {

  protected params = signal<Signal<LoadCollectionParameters> | undefined>(undefined);
  protected resolvedParams: Signal<LoadCollectionParameters | 'NO_QUERY'> = computed(() => {
    const params = this.params();
    if(!params)
      return 'NO_QUERY';
    return params();
  })


  protected _resource = cancelableRxResource({
    request: () => ({
      params: this.resolvedParams(),
    }),
    loader: (param) => {
      if(param.request.params === 'NO_QUERY')
        return of([]);
      return this.repository.load(param.request.params);
    }
  });

  resource = this._resource.asReadonly();
  loading = this._resource.isLoading;
  error = this._resource.error;

  items = computed(() => this._resource.value() ?? []);
  count = computed(() => this.items().length);

  constructor(protected repository: ReadonlyListRepository<T[]>, private options: RepositoryListServiceOptions<T> = {}) {
    if(options.defaultItems)
      this._resource.set(options.defaultItems);
  }

  cancel(): void {
    this._resource.cancel()
  }

  reload(): void {
    this._resource.reload();
  }

  registerParams(params: Signal<LoadCollectionParameters>): void {
    this.params.set(params)
  }

  unregisterParams() {
    this.params.set(undefined)
  }
}
