import { ChangeDetectionStrategy, Component, inject, input, model } from '@angular/core';
import { NgIf } from '@angular/common';
import { DateService } from '@softline/core';
import { DatePickerComponent } from '../../../components/picker/date-picker/date-picker.component';
import { ResolvableModal } from '../../abstraction/modal';
import { I18nModule } from '../../../i18n/i18n.module';

@Component({
  selector: 'soft-date-input-dialog',
  templateUrl: './date-picker-modal.component.html',
  styleUrls: ['./date-picker-modal.component.scss'],
  imports: [DatePickerComponent, I18nModule, NgIf],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class DatePickerModalComponent extends ResolvableModal<string | null> {
  readonly dateService = inject(DateService);
  readonly today = this.dateService.today();

  title = input<string | null>();
  subtitle = input<string | null>();
  value = model<string | null>();
}
