import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { CommonModule } from '@angular/common';
import {
  MessageBarStore,
  SOFTLINE_DATA_CONTEXT,
  SOFTLINE_FEATURE_MESSAGE_BAR,
  UiCoreModule,
  Validators
} from "@softline/ui-core";
import { FormControl, FormGroup, ReactiveFormsModule } from "@angular/forms";
import {
  BehaviorSubject,
  combineLatestWith, distinctUntilChanged,
  firstValueFrom,
  lastValueFrom,
  map, skip, startWith,
  Subject,
  Subscription,
  switchMap, tap
} from "rxjs";
import {
  FreigabeStore,
  SOFTLINE_CONST_WORKFLOW_DATA_DETAIL,
  SOFTLINE_FEATURE_FREIGABE,
  SOFTLINE_MESSAGE_SOFTAPPS_FIBU_FREIGABE_LOADED,
  SOFTLINE_MESSAGE_SOFTAPPS_FIBU_FREIGABE_NEXT, SOFTLINE_MESSAGE_SOFTAPPS_FIBU_FREIGABE_REQUEST_NEXT,
  Workflow,
  WorkflowData
} from "@softapps/fibu/freigabe";
import {
  asType,
  base64Decode,
  ConnectionHttpService, ApiResourceLocation,
  HttpService,
  Mediator, ResourceService,
  SOFTLINE_SERVICE_HTTP,
  Store
} from "@softline/core";
import {
  ArchiveFile,
  ArchiveStore,
  Command,
  CommandStore,
  showRequestErrors,
  SOFTLINE_FEATURE_ARCHIVE, SOFTLINE_FEATURE_COMMANDS
} from "@softline/application";
import { PdfEditorStore, SOFTLINE_FEATURE_PDF_EDITOR } from "@softapps/allgemein/pdf-editor";
import { AbauWorkflowData } from "../../types/abau-workflow-data";

const SOFTLINE_API_RECALCULATE = "/v1/workflow/entities/{{id}}/data/recalculate";

@Component({
    selector: 'softline-abau-freigabe',
    imports: [CommonModule, UiCoreModule, ReactiveFormsModule],
    templateUrl: './abau-freigabe.widget.html',
    styleUrls: ['./abau-freigabe.widget.scss']
})
export class AbauFreigabeWidget implements OnInit, OnDestroy {

  private formSubscription?: Subscription;
  private workflowSubscription?: Subscription;
  private mediatorSubscription?: Subscription;

  private submitting = false;

  form = new FormGroup({
    id: new FormControl(undefined),
    faelligkeitMitglied: new FormControl(undefined),
    faelligkeitLieferant: new FormControl(undefined),
    wfprocessid: new FormControl(undefined),
    abstrichNetto: new FormControl(20, [Validators.min(0)]),
    ruecklassProzent: new FormControl(undefined, [Validators.min(0)]),
    ruecklassWert: new FormControl(undefined),
    skontoProzKu: new FormControl(undefined, [Validators.min(0)]),
    skontoWertKu: new FormControl(undefined),
    begruendung: new FormControl(undefined, { updateOn: 'blur', validators: [Validators.min(0)] }),
    files: new FormControl<File[] | null>(null)
  });

  saving$ = new BehaviorSubject<boolean>(false);
  formState$ = this.form.statusChanges.pipe(
    startWith(undefined)
  )

  workflow$ = this.store.observe(
    SOFTLINE_FEATURE_FREIGABE,
    FreigabeStore.getters.entityWithDetails,
    this.context
  )
    .pipe(asType<Workflow & {detail: AbauWorkflowData}>())

  faelligkeitFeldAenderung?: string = undefined;

  constructor(private store: Store,
              @Inject(SOFTLINE_DATA_CONTEXT) private context: number,
              private mediator: Mediator,
              @Inject(SOFTLINE_SERVICE_HTTP) private httpService: ResourceService<ApiResourceLocation>) { }

  async ngOnInit(): Promise<void> {
    this.formSubscription = this.form.valueChanges
      .pipe(distinctUntilChanged((prev, curr) => {
        return prev.abstrichNetto === curr.abstrichNetto &&
          prev.id === curr.id &&
          prev.wfprocessid === curr.wfprocessid &&
          prev.faelligkeitMitglied === curr.faelligkeitMitglied &&
          prev.begruendung === curr.begruendung &&
          prev.faelligkeitLieferant === curr.faelligkeitLieferant &&
          prev.files === curr.files &&
          prev.ruecklassProzent === curr.ruecklassProzent &&
          prev.skontoProzKu === curr.skontoProzKu &&
          prev.skontoWertKu === curr.skontoWertKu
      }))
      .subscribe((o) => {
      if (this.submitting)
        return;

      this.recalculate(o as any)
    });

    this.workflowSubscription = this.workflow$.subscribe(o => {
      if (o)
        this.form.patchValue(o?.detail as any, { emitEvent: false, onlySelf: true });
    });

    this.mediatorSubscription = this.mediator.subscribe(async (message) => {
      switch (message.name) {
        case SOFTLINE_MESSAGE_SOFTAPPS_FIBU_FREIGABE_NEXT:
          await this.save();
          break;
        default:
          break;
      }
    });
    this.store.commit(SOFTLINE_FEATURE_COMMANDS, CommandStore.mutations.addSet, {
      name: AbauFreigabeWidget,
      commands: this.createCommands()
    })
  }

  ngOnDestroy(): void {
    if (this.formSubscription && !this.formSubscription.closed)
      this.formSubscription.unsubscribe();
    this.formSubscription = undefined;
    if (this.mediatorSubscription && !this.mediatorSubscription.closed)
      this.mediatorSubscription.unsubscribe();
    this.mediatorSubscription = undefined;
    if (this.workflowSubscription && !this.workflowSubscription.closed)
      this.workflowSubscription.unsubscribe();
    this.workflowSubscription = undefined;
    this.store.commit(SOFTLINE_FEATURE_COMMANDS, CommandStore.mutations.removeSet, AbauFreigabeWidget)
  }


  createCommands(): Command[] {
    return [
      {
        icon: 'fas fa-save',
        name: '#FREIGABE.WIDGET.ABAU_FREIGABE.SAVE',
        class: 'menu action-menu action-menu-top',
        canExecute: this.saving$.pipe(
          combineLatestWith(this.formState$),
          map(([o, state]) => !o && state !== 'INVALID')
        ),
        execute: () => this.save(),
      },
    ];
  }

  private async recalculate(
    value: Partial<{
      abstrichNetto: number;
      ruecklassProzent: number;
      skontoProzKu: number;
      wfprocessid: string;
      faelligkeitMitglied: string;
      faelligkeitLieferant: string;
    }>
  ): Promise<void> {
    const workflow = await firstValueFrom(this.workflow$);
    if (workflow.detail.abstrichNetto === value.abstrichNetto
      && workflow.detail.ruecklassProzent === value.ruecklassProzent
      && workflow.detail.skontoProzKu === value.skontoProzKu
      && workflow.detail.faelligkeitMitglied === value.faelligkeitMitglied
      && workflow.detail.faelligkeitLieferant === value.faelligkeitLieferant) {
      this.store.commit(
        SOFTLINE_FEATURE_FREIGABE,
        FreigabeStore.mutations.patchDetail,
        {
          name: SOFTLINE_CONST_WORKFLOW_DATA_DETAIL,
          id: workflow.detail.wfprocessid,
          value: value,
        }
      );
      return;
    }

    try {
      this.form.disable({emitEvent: false});
      const result = await lastValueFrom(this.httpService.create<any, any>({
        path: SOFTLINE_API_RECALCULATE,
        pathParams: {id: workflow.id}
      }, {...workflow.detail, ...value, faelligkeitFeldAenderung: this.faelligkeitFeldAenderung}));

      // this.form.patchValue(result, { emitEvent: false });
      this.store.commit(
        SOFTLINE_FEATURE_FREIGABE,
        FreigabeStore.mutations.updateDetail,
        {
          name: SOFTLINE_CONST_WORKFLOW_DATA_DETAIL,
          id: result.wfprocessid,
          value: result,
        }
      );

      this.form.enable({emitEvent: false});
    }
    catch (e) {
      this.form.enable({emitEvent: false});
      showRequestErrors(this.store, e);
    }
  }


  async onSubmit(): Promise<void> {
    try {
      this.submitting = true;
      await this.save();
      await this.mediator.next({
        name: SOFTLINE_MESSAGE_SOFTAPPS_FIBU_FREIGABE_REQUEST_NEXT
      });
    } catch (e) {
      showRequestErrors(this.store, e);
      this.submitting = false;
    }
  }

  async save(): Promise<void> {
    const workflow = await firstValueFrom(this.workflow$);
    const { files, ...value } = this.form.value;
    if(!workflow.detail.wfprocessid)
      throw new Error('[freigabe.component] onSubmit:  No wfprocessid')
    try {
      this.saving$.next(true);
      await this.uploadDocument(workflow);

      if (files && files?.length > 0 && workflow?.detail?.archiveKey) {
        await this.store.dispatch(
          SOFTLINE_FEATURE_ARCHIVE,
          ArchiveStore.actions.upload,
          {
            archiveKey: workflow?.detail?.archiveKey,
            files: files || [],
          }
        );
      }

      await this.store.dispatch(
        SOFTLINE_FEATURE_FREIGABE,
        FreigabeStore.actions.patchDetail,
        {
          name: SOFTLINE_CONST_WORKFLOW_DATA_DETAIL,
          patch: { id: workflow.detail.wfprocessid, changes: value as any },
        }
      );
      await this.store.dispatch(
        SOFTLINE_FEATURE_MESSAGE_BAR,
        MessageBarStore.actions.success,
        '#FREIGABE.MESSAGES.SUCCESS.SAVE'
      );
    } catch (e) {
      showRequestErrors(this.store, e);
    } finally {
      this.saving$.next(false);
    }
  }

  private async uploadDocument(
    workflow: Workflow & { detail: WorkflowData }
  ): Promise<void> {
    const editedDocument = this.store.get(
      SOFTLINE_FEATURE_PDF_EDITOR,
      PdfEditorStore.getters.document,
      workflow.detail.fileKey
    ) as any;
    if (!workflow.detail.fileKey || !workflow.detail.archiveKey || !editedDocument?.edited) return;

    const fileName = (
      JSON.parse(base64Decode(workflow.detail.fileKey)) as ArchiveFile
    ).metaData.name;
    const file = new File([editedDocument.data], fileName);
    await this.store.dispatch(
      SOFTLINE_FEATURE_ARCHIVE,
      ArchiveStore.actions.upload,
      {
        archiveKey: workflow.detail.archiveKey,
        files: [file],
      }
    );
    await this.store.commit(
      SOFTLINE_FEATURE_PDF_EDITOR,
      PdfEditorStore.mutations.remove,
      workflow.detail.fileKey
    );
    await this.store.dispatch(
      SOFTLINE_FEATURE_MESSAGE_BAR,
      MessageBarStore.actions.success,
      '#FREIGABE.MESSAGES.SUCCESS.UPLOAD'
    );
  }

}
