import { Inject, inject, Injector, Signal, TemplateRef } from '@angular/core';
import { Dismissed, ResolvableModalConfig } from '../types/modal-config';
import { ModalService } from '../modal.service';
import { QuestionModalComponent } from '../components/question-modal/question-modal.component';
import { QuestionResult } from '../types/question-result';

export interface DeleteModalConfig2 {
  title?: string | Signal<string | null>;
  subtitle?: string | Signal<string | null>;
  text?: string | Signal<string | null>;
  content?: TemplateRef<any> | Signal<TemplateRef<any> | null>;
  contentContext?: object | Signal<object | null>;
  question: string | Signal<string>;
  showCancel?: boolean;
  class?: string;
  dismiss?: boolean;
}

async function askDelete(modalService: ModalService, config: DeleteModalConfig2, injector: Injector): Promise<QuestionResult | Dismissed> {
  const modalConfig: ResolvableModalConfig<QuestionModalComponent, QuestionResult> = {
    component: QuestionModalComponent,
    dismiss: config.dismiss,
    title: config.title ?? 'Löschen',
    subtitle: config.subtitle ?? 'Möchten Sie diesen Eintag wirklich löschen?',
    text: config.text,
    template: config.content,
    templateContext: config.contentContext,
    priority: Number.POSITIVE_INFINITY,
    data: {
      showCancel: config.showCancel,
    },
    class: config.class,
    injector: injector
  }
  return await modalService.open(modalConfig);
}

export function askDeleteFn(): (config: DeleteModalConfig2) => Promise<QuestionResult | Dismissed> {
  const modalService = inject(ModalService);
  const injector = inject(Injector);
  return (config) => askDelete(modalService, config, injector)
}
