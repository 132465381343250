<div class="g gtc-3 gtr-1">
  <div class="gc-1 gr-center">
    <div class="flex flex-col gap-0.5 mt-1">
      <label class="soft-label soft-label-top whitespace-nowrap w-28">{{
        '#UI_CORE.PAGINATOR.PAGESIZE' | translate
      }}</label>
      <soft-select
        class="w-20"
        [value]="pageSize"
        (valueChange)="setPageSize($event)">
        <soft-select-option *ngFor="let size of pageSizes" [value]="size">{{
          size
        }}</soft-select-option>
      </soft-select>
    </div>
  </div>
  <span class="gc-2 gc-center gr-center">
    <div class="flex flex-col text-center items-center justify-center">
      <span class="text-sm font-semibold">Seite {{ page }} von {{ pageCount }}</span>
      <span class="text-xs text-light">{{
          '#UI_CORE.PAGINATOR.INFO'
            | translate
            | interpolate
            : [
              1 + (page - 1) * pageSize,
              [page * pageSize, elements] | min,
              elements
            ]
        }}</span>
    </div>

  </span>
  <div class="flex items-center justify-end">
    <svg xmlns="http://www.w3.org/2000/svg"
         xmlns="http://www.w3.org/2000/svg"
         [class.disabled]="!isPreviousPageEnabled"
         (click)="onFirstPage()"
         class="fill-current text-color-light cursor-pointer w-7 h-7 p-1 hover:text-color hover:bg-hover rounded" viewBox="0 0 512 512">
      <path d="M47 239c-9.4 9.4-9.4 24.6 0 33.9L207 433c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9L97.9 256 241 113c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0L47 239zM399 79L239 239c-9.4 9.4-9.4 24.6 0 33.9L399 433c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9l-143-143L433 113c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0z"/>
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg"
         [class.disabled]="!isPreviousPageEnabled"
         (click)="onPreviousPage()"
         class="fill-current cursor-pointer w-7 h-7 p-1 hover:bg-hover rounded" viewBox="0 0 320 512">
       <path d="M47 239c-9.4 9.4-9.4 24.6 0 33.9L207 433c9.4 9.4 24.6 9.4 33.9 0s9.4-24.6 0-33.9L97.9 256 241 113c9.4-9.4 9.4-24.6 0-33.9s-24.6-9.4-33.9 0L47 239z"/>
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg"
         [class.disabled]="!isNextPageEnabled"
         (click)="onNextPage()"
         class="fill-current cursor-pointer w-7 h-7 p-1 hover:bg-hover rounded" viewBox="0 0 320 512">
      <path d="M273 239c9.4 9.4 9.4 24.6 0 33.9L113 433c-9.4 9.4-24.6 9.4-33.9 0s-9.4-24.6 0-33.9l143-143L79 113c-9.4-9.4-9.4-24.6 0-33.9s24.6-9.4 33.9 0L273 239z"/>
    </svg>
    <svg xmlns="http://www.w3.org/2000/svg"
         [class.disabled]="!isNextPageEnabled"
         (click)="onLastPage()"
         class="fill-current cursor-pointer w-7 h-7 p-1 hover:bg-hover rounded" viewBox="0 0 512 512">
      <path d="M113 433L273 273c9.4-9.4 9.4-24.6 0-33.9L113 79c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l143 143L79 399c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0zm192 0L465 273c9.4-9.4 9.4-24.6 0-33.9L305 79c-9.4-9.4-24.6-9.4-33.9 0s-9.4 24.6 0 33.9l143 143L271 399c-9.4 9.4-9.4 24.6 0 33.9s24.6 9.4 33.9 0z"/>
    </svg>
</div>
