import {
  ApplicationConfig,
  inject,
  LOCALE_ID,
  provideEnvironmentInitializer,
} from '@angular/core';
import { provideRouter, withEnabledBlockingInitialNavigation } from '@angular/router';
import { routes } from './app.routes';
import { Store } from '@softline/core';
import {
  SOFTLINE_CONFIG_WIDGETS,
  SOFTLINE_FEATURE_TRANSLATION,
  TranslationStore,
} from '@softline/ui-core';
import { environment } from '../environments/environment';
import { AddOn, remoteConfigProviders } from '@softline/application';
import {
  SOFTLINE_CONFIG_DEFINITION,
  SOFTLINE_CONFIG_FIELD_OK,
  SOFTLINE_CONFIG_LOAD_CUSTOM_DEFINITIONS,
} from '@softline/dynamic';
import {
  accountContextProviders,
  contextSettingsProviders,
  settingsProviders,
  SOFTLINE_CONFIG_CONTEXT_ACCOUNT,
  userContextProviders,
  utilitiesProviders,
} from '@softapps/allgemein/utils';
import { pdfViewerProviders } from '@softapps/allgemein/pdf-viewer';
import { pdfEditorProviders } from '@softapps/allgemein/pdf-editor';
import { loginProviders, withLogin } from '@softapps/allgemein/login';
import { ssoProviders } from '@softapps/allgemein/sso';
import { moduleProviders } from '@softapps/allgemein/modules';
import {
  ArchivbelegDownloadStrategy,
  BelegDownloadStrategy,
  DatentraegerDownloadStrategy,
  FremdbelegDownloadStrategy,
  gesamtabfrageProviders,
  SOFTLINE_CONFIG_GESAMTABFRAGE,
  SOFTLINE_DEFINITION_VOUCHER_BUSINESS_CASE,
  SOFTLINE_DEFINITION_VOUCHER_LIST,
  SOFTLINE_DEFINITION_VOUCHER_QUERY,
} from '@softapps/wws/gesamtabfrage';
import { subkundenProviders } from '@softapps/wws/subkunden';
import {
  freigabeProviders,
  SOFTLINE_DEFINITION_FREIGABE_DETAIL,
  SOFTLINE_DEFINITION_FREIGABE_LIST,
  SOFTLINE_WIDGET_SOFTAPPS_FIBU_FREIGABE,
} from '@softapps/fibu/freigabe';
import {
  haftruecklassProviders,
  SOFTLINE_CONFIG_HAFTRUECKLASS,
  SOFTLINE_DEFINITION_HAFTRUECKLASS_LIST,
  SOFTLINE_DEFINITION_HAFTRUECKLASS_QUERY,
} from '@softapps/fibu/haftruecklass';
import { offenePostenProviders } from '@softapps/fibu/offene-posten';
import { buchungsbriefProviders } from '@softapps/abau/buchungsbrief';
import { platform } from '../platforms/platform';

import { default as de } from '../i18n/de.json';
import { default as opListDefinition } from '../definitions/haftruecklass/haftruecklass-list.definition.json';
import { default as opQueryDefinition } from '../definitions/haftruecklass/haftruecklass-query.definition.json';
import { default as rechnungListDefinition } from '../definitions/gesamtabfrage/rechnung/gesamtabfrage-list.definition.json';
import { default as rechnungQueryDefinition } from '../definitions/gesamtabfrage/rechnung/gesamtabfrage-query.definition.json';
import { default as rechnungspruefungListDefinition } from '../definitions/gesamtabfrage/rechnungspruefung/gesamtabfrage-list.definition.json';
import { default as rechnungspruefungQueryDefinition } from '../definitions/gesamtabfrage/rechnungspruefung/gesamtabfrage-query.definition.json';
import { default as businessCaseDefinition } from '../definitions/gesamtabfrage/business-case-list.definition.json';
import { default as freigabeListDefinition } from '../definitions/freigabe/freigabe-list.definition.json';
import { default as freigabeDetailDefinition } from '../definitions/freigabe/freigabe-details.definition.json';

import { ChangeBaustelleAddOn } from '../add-ons/change-baustelle-addon';
import { AbauFreigabeWidget } from '../widgets/abau-freigabe/abau-freigabe.widget';
import {
  createKtostammKundeSubnummerFieldOkConfig,
  wwsCoreProviders,
} from '@softapps/wws/core';
import { provideSoftApps } from '@softapps/core';
import { provideAbauSaleStatistics } from '@softapps/abau/umsatzstatistik';

export const appConfig: ApplicationConfig = {
  providers: [
    provideRouter(routes, withEnabledBlockingInitialNavigation()),
    provideSoftApps({ connection: environment.connection }, withLogin()),
    ...remoteConfigProviders,

    //Allgemein
    ...utilitiesProviders,
    ...contextSettingsProviders,
    ...userContextProviders,
    ...accountContextProviders,
    ...settingsProviders,

    ...wwsCoreProviders,

    ...pdfViewerProviders,
    ...pdfEditorProviders,
    ...loginProviders,
    ...ssoProviders,
    ...moduleProviders,

    ...gesamtabfrageProviders,
    ...subkundenProviders,
    ...freigabeProviders,
    ...haftruecklassProviders,
    ...offenePostenProviders,
    ...buchungsbriefProviders,

    provideAbauSaleStatistics(),

    ...platform.providers,
    { provide: LOCALE_ID, useValue: 'de' },
    provideEnvironmentInitializer(() => {
      const store = inject(Store);
      store.commit(SOFTLINE_FEATURE_TRANSLATION, TranslationStore.mutations.add, {
        module: 'abau',
        language: 'de',
        translations: de,
      });
    }),
    {
      provide: SOFTLINE_CONFIG_HAFTRUECKLASS,
      useValue: {
        nurHaftRuecklaesse: true,
      },
    },
    {
      provide: SOFTLINE_CONFIG_GESAMTABFRAGE,
      useValue: {
        geschaeftsfall: true,
        download: [
          {
            belegart: 'Rechnung',
            strategies: [
              BelegDownloadStrategy,
              FremdbelegDownloadStrategy,
              DatentraegerDownloadStrategy,
            ],
          },
          {
            belegart: 'Rechnungspruefung',
            strategies: [ArchivbelegDownloadStrategy],
          },
        ],
      },
    },
    { provide: AddOn, useClass: ChangeBaustelleAddOn, multi: true },
    {
      provide: SOFTLINE_CONFIG_CONTEXT_ACCOUNT,
      useValue: {
        widgetLinkColor: '#EDCCCA !important',
      },
    },
    { provide: SOFTLINE_CONFIG_LOAD_CUSTOM_DEFINITIONS, useValue: false },
    {
      provide: SOFTLINE_CONFIG_DEFINITION,
      useValue: {
        name: SOFTLINE_DEFINITION_HAFTRUECKLASS_QUERY,
        definition: opQueryDefinition,
        priority: 1,
      },
      multi: true,
    },
    {
      provide: SOFTLINE_CONFIG_DEFINITION,
      useValue: {
        name: SOFTLINE_DEFINITION_HAFTRUECKLASS_LIST,
        definition: opListDefinition,
        priority: 1,
      },
      multi: true,
    },
    {
      provide: SOFTLINE_CONFIG_DEFINITION,
      useValue: {
        name: `${SOFTLINE_DEFINITION_VOUCHER_LIST}+Rechnung`,
        definition: rechnungListDefinition,
        priority: 1,
      },
      multi: true,
    },
    {
      provide: SOFTLINE_CONFIG_DEFINITION,
      useValue: {
        name: `${SOFTLINE_DEFINITION_VOUCHER_QUERY}+Rechnung`,
        definition: rechnungQueryDefinition,
        priority: 1,
      },
      multi: true,
    },
    {
      provide: SOFTLINE_CONFIG_DEFINITION,
      useValue: {
        name: `${SOFTLINE_DEFINITION_VOUCHER_LIST}+Rechnungspruefung`,
        definition: rechnungspruefungListDefinition,
        priority: 1,
      },
      multi: true,
    },
    {
      provide: SOFTLINE_CONFIG_DEFINITION,
      useValue: {
        name: `${SOFTLINE_DEFINITION_VOUCHER_QUERY}+Rechnungspruefung`,
        definition: rechnungspruefungQueryDefinition,
        priority: 1,
      },
      multi: true,
    },
    {
      provide: SOFTLINE_CONFIG_DEFINITION,
      useValue: {
        name: `${SOFTLINE_DEFINITION_VOUCHER_BUSINESS_CASE}`,
        definition: businessCaseDefinition,
        priority: 1,
      },
      multi: true,
    },
    {
      provide: SOFTLINE_CONFIG_DEFINITION,
      useValue: {
        name: `${SOFTLINE_DEFINITION_FREIGABE_LIST}`,
        definition: freigabeListDefinition,
        priority: 1,
      },
      multi: true,
    },
    {
      provide: SOFTLINE_CONFIG_DEFINITION,
      useValue: {
        name: `${SOFTLINE_DEFINITION_FREIGABE_DETAIL}`,
        definition: freigabeDetailDefinition,
        priority: 1,
      },
      multi: true,
    },
    {
      provide: SOFTLINE_CONFIG_WIDGETS,
      multi: true,
      useValue: {
        outlet: SOFTLINE_WIDGET_SOFTAPPS_FIBU_FREIGABE,
        component: AbauFreigabeWidget,
      },
    },
    {
      provide: SOFTLINE_CONFIG_FIELD_OK,
      multi: true,
      useValue: createKtostammKundeSubnummerFieldOkConfig({
        defaultView: 'list',
        autoQuery: true,
      }),
    },
  ],
};
