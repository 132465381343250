import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';
import { RemoteObjectStore, Store } from '@softline/core';
import {
  SOFTLINE_FEATURE_UTILITIES_FIRMA,
  SOFTLINE_FEATURE_UTILITIES_PATCH_CONTEXT,
  SOFTLINE_FEATURE_UTILITIES_VKFORGS,
} from '../../../../utilities.shared';
import { map } from 'rxjs/operators';
import { PatchContextStore } from '../../../patch-context.store';
import { SOFTLINE_FEATURE_MASTER_DATA } from '@softline/application';
import { CommonModule } from '@angular/common';
import { UiCoreModule } from '@softline/ui-core';
import { VkforgStore } from '../../../vkforg.store';
import { combineLatest } from 'rxjs';
import { FirmaStore } from '../../../firma.store';

export type VkforgContext = { vkforgid?: number };

@Component({
  selector: 'lib-account-context-editor',
  templateUrl: './vkforg-context-editor.component.html',
  styleUrls: ['./vkforg-context-editor.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  imports: [CommonModule, UiCoreModule],
})
export class VkforgContextEditorComponent implements OnInit {
  readonly firmen$ = this.store.observe(SOFTLINE_FEATURE_UTILITIES_FIRMA, FirmaStore.getters.all);

  readonly vkforgs$ = this.store.observe(SOFTLINE_FEATURE_UTILITIES_VKFORGS, VkforgStore.getters.all).pipe(map((o) => o ?? []));

  readonly vkforgContext$ = this.store
    .observe(SOFTLINE_FEATURE_UTILITIES_PATCH_CONTEXT, PatchContextStore.getters.data)
    .pipe(map((o) => o as VkforgContext));

  readonly vkforg$ = combineLatest([this.vkforgs$, this.vkforgContext$]).pipe(
    map(([o, context]) => o.find((v) => v.id === context?.vkforgid) ?? o[0])
  );

  readonly firma$ = combineLatest([this.firmen$, this.vkforg$]).pipe(map(([firmen, vkforg]) => firmen.find((o) => o?.id === vkforg?.idfirma)));

  constructor(private store: Store) {}

  async ngOnInit(): Promise<void> {
    await this.store.dispatch(SOFTLINE_FEATURE_MASTER_DATA, RemoteObjectStore.actions.load);
  }

  async onSubmit(vkforgid: number | undefined): Promise<void> {
    const vkforg = this.store.get(SOFTLINE_FEATURE_UTILITIES_VKFORGS, VkforgStore.getters.entity, vkforgid);

    if (!vkforg) return;

    this.store.commit(SOFTLINE_FEATURE_UTILITIES_PATCH_CONTEXT, PatchContextStore.mutations.patch, { vkforgid, firmaid: vkforg.idfirma });
  }
}
