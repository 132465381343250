<h6 class="font-semibold">
  {{ '#UTILITIES.ACCOUNT_CONTEXT.LABEL' | translate }}
</h6>
<soft-select
  [value]="(accountContext$ | async)?.accountid"
  (valueChange)="onChange($event)"
  #select
>
  <soft-select-option
    *ngFor="let account of accounts$ | async"
    [value]="account.id"
  >{{ account.name }}
  </soft-select-option
  >
</soft-select>
<!--
<ng-container *ngIf="activeAccount$ | async as activeAccount">
  <div class="c row space-between" *ngIf="!expanded">
  <span>
    {{activeAccount.number}} - {{activeAccount.name}}
  </span>
    <span>
    <a class="soft-link pointer" (click)="expanded = !expanded">{{'#UTILITIES.ACCOUNT_CONTEXT.CHANGE' | translate}}</a>
  </span>
  </div>

  <div *ngIf="expanded">
    <soft-radio-group class="soft-container column" [value]="activeAccount" #radio="softRadioGroup">
      <soft-radio-option *ngFor="let account of (accounts$ | async)" [value]="account" >
        <span class="soft-label right">{{account.name}}</span>
      </soft-radio-option>
    </soft-radio-group>
    <div class="soft-container row end">
      <button class="soft-button primary" (click)="onSubmit(radio.value)">{{'#UTILITIES.CHANGE_ACCOUNT.SUBMIT' | translate}}</button>
    </div>
  </div>
</ng-container>
-->
